import React from 'react';
import { ModelUsageTableColumns } from './ModelUsageTableColumns';
import { DataTable } from '@/ui/modules/DataTable/DataTable';
import { ModelUsageTableProps } from './ModelUsageTable.type.ts';

export const ModelUsageTable: React.FC<ModelUsageTableProps> = ({ data, showPagination }) => {
    return (
        <div>
            <DataTable columns={ModelUsageTableColumns} data={data} showPagination={showPagination} />
        </div>
    );
};
