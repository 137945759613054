import { FC } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

interface ConfirmDialogProps {
    isVisible: boolean;
    onClose: () => void;
    onDelete: () => void;
    bodyText: string;
    confirmButtonName?: string;
    rejectButtonName?: string;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
    isVisible,
    onClose,
    onDelete,
    bodyText,
    confirmButtonName = 'Yes',
    rejectButtonName = 'No',
}) => {
    return (
        <Dialog open={isVisible} onOpenChange={onClose}>
            <DialogContent aria-describedby={undefined}>
                <DialogHeader>
                    <DialogTitle>{bodyText}</DialogTitle>
                </DialogHeader>
                <DialogFooter>
                    <Button onClick={onDelete} variant="destructive">
                        {confirmButtonName}
                    </Button>
                    <Button onClick={onClose} variant="secondary">
                        {rejectButtonName}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmDialog;
