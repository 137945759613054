import { HomePage } from '@app/domain/information/pages/HomePage';
import { UseCases } from '@app/domain/information/pages/UseCases';
import { DocumentationPage } from '@app/domain/information/pages/DocumentationPage';
import DocsPage from '@app/domain/information/pages/DocsPage';

export const informationRoutes = [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/use-cases',
        element: <UseCases />,
    },
    {
        path: '/api-reference',
        element: <DocumentationPage />,
    },
    {
        path: '/docs',
        element: <DocsPage />,
    },
];
