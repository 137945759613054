import React, { useState } from 'react';
import MarkdownRenderer from './MarkdownRenderer';
import { SectionFrame } from '@ui/components/SectionFrame';
import { Heading } from '@ui/components/Heading';
import { Text } from '@ui/components/Text';
import { Button } from '@/components/ui/button';

const markdownFiles = [
    { title: 'Get Started', path: '/docs/getStarted.md' },
    { title: 'Open AI', path: '/docs/openAI.md' },
];

const DocsPage: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => setCurrentIndex((prev) => Math.min(prev + 1, markdownFiles.length - 1));
    const handleBack = () => setCurrentIndex((prev) => Math.max(prev - 1, 0));

    return (
        <SectionFrame id="docsView">
            <div className="my-20">
                <Heading variant="h1">Documentation</Heading>
                <Text>
                    Welcome to the documentation for the <Text bold>Exxeta AI Platform</Text>.
                </Text>
            </div>
            <MarkdownRenderer filePath={markdownFiles[currentIndex].path} />

            {}
            <div className="flex items-center justify-end space-x-2 py-4">
                <Button variant="outline" size="sm" onClick={handleBack} disabled={currentIndex === 0}>
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={handleNext}
                    disabled={currentIndex === markdownFiles.length - 1}
                >
                    Next
                </Button>
            </div>
        </SectionFrame>
    );
};

export default DocsPage;
