import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface CodeBlockProps {
    language: string;
    value: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ language, value }) => {
    return (
        <SyntaxHighlighter style={docco} language={language} showLineNumbers wrapLines>
            {value}
        </SyntaxHighlighter>
    );
};

export default CodeBlock;
