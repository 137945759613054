import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CodeBlock from '@ui/components/CodeBlock';
import CodeTabs from '@ui/components/CodeTabs';
import './MarkdownRenderer.css';

interface MarkdownRendererProps {
    filePath: string;
}

interface Tab {
    title: string;
    content: string;
}

interface Section {
    type: 'markdown' | 'tabs';
    content: string | Tab[];
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ filePath }) => {
    const [sections, setSections] = useState<Section[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchMarkdown = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(filePath);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const text = await response.text();
                parseContent(text);
            } catch (error) {
                setError('Error loading markdown file.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMarkdown();
    }, [filePath]);

    const parseContent = (markdownText: string) => {
        const sections: Section[] = [];
        const tabBlockRegex = /\[\[Tabs]]([\s\S]*?)\[\[\/Tabs]]/g;
        let lastIndex = 0;
        let match;

        while ((match = tabBlockRegex.exec(markdownText)) !== null) {
            if (match.index > lastIndex) {
                sections.push({ type: 'markdown', content: markdownText.slice(lastIndex, match.index).trim() });
            }

            const tabsContent = match[1];
            const tabs: Tab[] = [];
            const tabItemRegex = /\[\[Tab:(.*?)]]\s*(```[\s\S]*?```)/g;
            let tabMatch;

            while ((tabMatch = tabItemRegex.exec(tabsContent)) !== null) {
                tabs.push({
                    title: tabMatch[1].trim(),
                    content: tabMatch[2].trim(),
                });
            }

            sections.push({ type: 'tabs', content: tabs });
            lastIndex = match.index + match[0].length;
        }

        if (lastIndex < markdownText.length) {
            sections.push({ type: 'markdown', content: markdownText.slice(lastIndex).trim() });
        }

        setSections(sections);
    };

    return (
        <div className="markdown-content">
            {isLoading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            {!isLoading && !error && (
                <>
                    {sections.map((section, idx) => {
                        if (section.type === 'markdown') {
                            return (
                                <ReactMarkdown
                                    key={idx}
                                    remarkPlugins={[remarkGfm]}
                                    components={{
                                        //CodeBlock
                                        code({ className, children }) {
                                            const match = /language-(\w+)/.exec(className || '');
                                            const language = match ? match[1] : 'text';
                                            const value = String(children).replace(/\n$/, '');
                                            return <CodeBlock language={language} value={value} />;
                                        },
                                        //INFO
                                        p({ children }) {
                                            const firstChild = React.Children.toArray(children)[0];
                                            if (
                                                React.isValidElement(firstChild) &&
                                                firstChild.props.children === 'INFO'
                                            ) {
                                                return (
                                                    <div className="info-block">
                                                        <strong className="info-label">INFO</strong>{' '}
                                                        <span>{React.Children.toArray(children).slice(1)}</span>
                                                    </div>
                                                );
                                            }
                                            return <p>{children}</p>;
                                        },
                                    }}
                                >
                                    {section.content as string}
                                </ReactMarkdown>
                            );
                            //Tabs
                        } else if (section.type === 'tabs') {
                            return <CodeTabs key={idx} tabs={section.content as Tab[]} />;
                        }
                        return null;
                    })}
                </>
            )}
        </div>
    );
};

export default MarkdownRenderer;
