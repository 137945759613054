'use client';

import { ColumnDef } from '@tanstack/react-table';

export type ModelUsage = {
    model_id: string;
    total_tokens: number;
};

export const ModelUsageTableColumns: ColumnDef<ModelUsage>[] = [
    {
        accessorKey: 'model_id',
        header: 'MODEL ID',
    },
    {
        accessorKey: 'total_tokens',
        header: () => <div className="text-right">USAGE TOKENS</div>,
        cell: ({ row }) => {
            const amount = parseFloat(row.getValue('total_tokens'));

            // Format the amount with periods as thousands separators
            const formatted = amount.toLocaleString('de-DE', {
                maximumFractionDigits: 0, // Removes any decimal places
            });

            return <div className="text-right font-medium">{formatted}</div>;
        },
    },
];
