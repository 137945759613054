import NavbarImpl from '@ui/modules/Navbar';
import { useUserContext } from '../context/UserContext';
import { useNavigationContext } from '@app/domain/platform/context/NavigationContext';

export const Navbar = () => {
    const {
        userData: { isUser, name },
    } = useUserContext();

    const userName = isUser ? name.split(' ')[0] : 'Login';

    const { pathname } = useNavigationContext();

    return (
        <NavbarImpl
            pathname={pathname}
            burgerLinks={[
                {
                    href: '/',
                    children: 'Home',
                },
                {
                    href: '/use-cases',
                    children: 'Use Cases',
                },
                {
                    href: '/docs',
                    children: 'Docs',
                },
                {
                    href: '/api-reference',
                    children: 'Swagger API Reference',
                },
                {
                    href: '/user',
                    children: <>Profile: {userName}</>,
                },
                {
                    href: '/admin',
                    children: 'Admin',
                },
            ]}
        ></NavbarImpl>
    );
};
