import React from 'react';
import { AppUsageTableColumns } from './AppUsageTableColumns';
import { DataTable } from '@/ui/modules/DataTable/DataTable';
import { AppUsageTableProps } from './AppUsageTable.type.ts';

export const AppUsageTable: React.FC<AppUsageTableProps> = ({ data, showPagination }) => {
    return (
        <div>
            <DataTable columns={AppUsageTableColumns} data={data} showPagination={showPagination} />
        </div>
    );
};
